import { ObjectFitProperty } from "csstype"

import React from 'react'

import {actions as Settings} from '../reducers/Settings'
import {objectFits} from '../reducers/Settings'
import AspectRatios from '../AspectRatios.json'
import { useSelector, dispatch } from '../reducers/RootReducer'
import './Help.css'

const shortCuts = [
	['C',"Clone video (+1m)"],
	['D',"Distribute times"],
	['E',"Remove everything else"],
	['F',"Toggle fullscreen"],
	['Shift+F',"Fullscreen video"],
	['H',"Toggle help"],
	['I',"Toggle in point"],
	['O',"Toggle out point"],
	['M',"Toggle mute"],
	['R',"Remove video"],
	['S',"Change video scaling"],
	['X',"Change aspect ratio"],
	['← →',"Skip 1m"],
	['Shift ← →',"Skip 10%"],
	['Ctrl ← →',"Change speed"],
	['Ctrl+W',"Close tab"],
	['O,O',"Restart video"],
	['1-9',"Fill 2-9 size grid"],
	['Drag+Drop',"Reorder videos"],
]

type ToggleButtonProps = {
	value:boolean
	callback:()=>void
	onText:string
	offText:string
}
const ToggleButton = ({value, callback, onText, offText}:ToggleButtonProps) => {
	return <button onClick={e=>{
		e.preventDefault()
		callback()
	}}>{value ? onText : offText}</button>
}

const SHORTCUTS = <section id="shortcuts">
	<h2>Shortcuts</h2><ol>
		{shortCuts.map(([key, desc]) =>
			<li key={key}><em>{key}:</em> {desc}</li>)}
	</ol>
</section>

const DISCLAIMER = <footer>
	<h2>Privacy Disclaimer</h2>
	<p>This tool records <b>no</b> filenames, screen grabs, or any other methods of identifying the actual contents of any video.  Only metadata about a video's format (codec, file size, resolution, and duration) may be recorded.</p>
</footer>

export const Help = () => {
	const {aspect, fit, playbackRate: {current: playbackRate}, showThumbnails: {current: showThumbnails}, muted: {current: muted}} = useSelector(state => state.settings)
	return <section id="help">
		<h2>Usage</h2>
		<p>Drag and drop any number of videos to auto-play in an optimally arranged grid.</p>
		<section id="settings">
			<h2>Settings</h2>
			<form>
				<SelectInput
					name="objectScale" label="Video Fit/Fill"
					value={fit} choices={objectFits.map(v => <option key={v} value={v}>{v[0].toUpperCase()+v.substr(1)}</option>)}
					callback={v=>dispatch(Settings.setFit(v as ObjectFitProperty))} /><br />

				<SelectInput
					name="aspectRatio" label="Aspect Ratio"
					value={AspectRatios.indexOf(aspect).toString()}
					choices={AspectRatios.map((v, i) => <option key={i} value={i}>{v.name}</option>)}
					callback={i=>dispatch(Settings.setAspect(AspectRatios[parseInt(i)]))} /><br />

				<div>Playback Rate: <button onClick={e=>{
					e.preventDefault()
					dispatch(Settings.setPlaybackRate(playbackRate / 2))
				}}>-</button>{playbackRate.toFixed(2)}<button onClick={e=>{
					e.preventDefault()
					dispatch(Settings.setPlaybackRate(playbackRate * 2))
				}}>+</button></div>

				{/* <button onClick={e=>{
					e.preventDefault()
					dispatch(Settings.toggleThumbnails())
				}} name="showThumbnails">{showThumbnails ? 'Disable Thumbs' : 'Enable Thumbs'}</button> */}
				<ToggleButton
					value={showThumbnails}
					callback={()=>dispatch(Settings.toggleThumbnails())}
					onText="Disable Thumbs"
					offText="Enable Thumbs" /><br />

				<ToggleButton
					value={muted}
					callback={()=>dispatch(Settings.toggleMute())}
					onText="Unmute"
					offText="Mute" /><br />
			</form>
		</section>
		{SHORTCUTS}
		{DISCLAIMER}
	</section>
}

type SelectInputType = {
	name:string
	label:string
	value:string
	choices:JSX.Element[]
	callback:(val:string)=>void
}
function SelectInput({name, label, value, choices, callback}:SelectInputType) {
	return <>
		<label htmlFor={name}>{label}</label>
		<select name={name} value={value} onChange={e => callback(e.target.value)}>
			{choices}
		</select>
	</>
}

// type NumInputProps = {
// 	value:number
// 	name:string
// 	label:string
// 	callback:(num:number)=>void
// }
// const NumInput = ({value,name,label,callback}:NumInputProps) => <>
// 	<label htmlFor={name}>{label}</label>
// 	<input type="number" min={0} maxLength={3} name={name} value={value} onChange={e => callback(parseFloat(e.target.value))} />
// </>

export default Help