import React from 'react'
import { Display } from '../reducers/Displays'

type ErrorDisplayProps = {
	errors:Display[]
	onClose:()=>void
}
export const ErrorDisplay = ({errors, onClose}:ErrorDisplayProps) => {

	return <section id="errors">
		<h2>Errors</h2><ol>
			{errors.map(({id, title, type}) => <li key={id}>{title} ({type})</li>)}
		</ol>
		<p>Only videos supported by your web browser will play successfully.  <code>.mp4</code> and <code>.webm</code> files are good bets.</p>
		<form onSubmit={onClose}><button>Dismiss</button></form>
	</section>
}
