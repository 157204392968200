import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {ObjectFitProperty} from 'csstype'
import aspectRatios from '../AspectRatios.json'

export const objectFits:ObjectFitProperty[] = ['contain', 'cover', 'fill', 'scale-down']
export type AspectRatio = typeof aspectRatios[number]

export type Setting<T> = {
	readonly current:T
}

const getNext = <T,>(list:T[], current:T) =>
	list[(list.indexOf(current) + 1) % list.length]

export type Settings = {
	fit:ObjectFitProperty
	aspect:AspectRatio
	playbackRate:Setting<number>
	showThumbnails:Setting<boolean>
	muted:Setting<boolean>
	triggers: {
		distributeTimes?:number
		setIn?:number
		setOut?:number
	}
}

export type Trigger = Partial<Settings['triggers']>

const initialState:Settings = {
	fit: objectFits[0],
	aspect: aspectRatios[0],
	playbackRate:{current: 1},
	showThumbnails:{current: true},
	muted:{current: true},
	triggers:{}
}
export const Settings = createSlice({
	name: 'settings',
	initialState,
	reducers: {
		nextAspect: (state) => {state.aspect = getNext(aspectRatios, state.aspect)},
		nextFit: (state) => {state.fit = getNext(objectFits, state.fit)},
		setAspect: (state, {payload}) => {state.aspect = payload},
		setFit: (state, {payload}:PayloadAction<ObjectFitProperty>) => {state.fit = payload},
		setPlaybackRate: (state, {payload}:PayloadAction<number>) => {state.playbackRate.current = payload},
		// does this update propertly thanks to immer or not?  Test!
		toggleThumbnails: (state) => {state.showThumbnails.current = !state.showThumbnails.current},
		toggleMute: (state) => {state.muted.current = !state.muted.current},
		// distributeTimes: (state, {payload}:PayloadAction<number>) => {state.triggers.distributeTimes = payload},
		// clearDistributeFlag: (state) => {state.triggers.distributeTimes = undefined},
		setTriggers: (state, {payload}:PayloadAction<Trigger>) => {state.triggers = {...state.triggers, ...payload}},
		clearTrigger: (state, {payload}:PayloadAction<keyof Settings['triggers']>) => {state.triggers[payload] = undefined},
		setIn: (state, {payload}:PayloadAction<number>) => {state.triggers.setIn = payload},
		setOut: (state, {payload}:PayloadAction<number>) => {state.triggers.setOut = payload}
	}
})

export const {actions, reducer} = Settings
