// TODO: import only required polyfills
// import 'react-app-polyfill/ie11'
// import '@babel/polyfill'
// import 'core-js/modules/es6.array.from'

// TODO: refence react as third party library on cdn?
import React from 'react'
import { PayloadAction, AnyAction } from '@reduxjs/toolkit'
import {useSelector, dispatch} from './reducers/RootReducer'
import {actions as Settings, Settings as SettingsType} from './reducers/Settings'
import {Display, actions as Displays, reducer as displayReducer} from './reducers/Displays'
import { BDVideo } from './BDVideo'
import Help from './components/Help'
import { ErrorDisplay } from './components/ErrorDisplay'
import { Splash } from './components/Splash'
import aspectRatios from './AspectRatios.json'
import './App.css'

import {getVideoSize} from './getVideoSize'
// import { AppReducer, objectFits, AppAction } from './AppReducer'
// import { AppAction } from './AppReducer'
import { toggleFullscreen } from './FullScreen'

const avg = (arr:number[]) => arr.reduce((a, b) => a + b, 0) / arr.length

const stopEvent = (e:Event) => {
	e.preventDefault()
	e.stopPropagation()
}

document.addEventListener('dragover', stopEvent)
document.addEventListener('dragenter', stopEvent)
document.addEventListener('dragleave', stopEvent)

const getVideoTimeOffset = (video:HTMLVideoElement, percentage:number) =>
	video.currentTime + (video.duration * percentage)

type TransitionalActionSet = Record<string, (()=>void)|PayloadAction>

export type Viewport = {
	width:number
	height:number
}

const useRefArray = <T,>() => {
	const [videos, setVideos] = React.useState<Record<string, React.RefObject<T>>>({})

	return React.useCallback((id:string) => {
		if (id in videos) return videos[id]
		const ref = React.createRef<T>()
		setVideos({...videos, [id]: ref})
		return ref
	}, [videos])
}

const App = () => {
	const getRef = useRefArray<HTMLVideoElement>()
	const [displays, dispatchDisplays] = React.useReducer(displayReducer, [])
	const {aspect, fit, showThumbnails, playbackRate, muted, triggers: {distributeTimes, setIn, setOut}} = useSelector(state => state.settings)
	const [active, setActive] = React.useState<number|null>(null)
	const [errors, setErrors] = React.useState<Display[]>([])
	const [help, setHelp] = React.useState(true)
	const [dragSrc, setDragSrc] = React.useState<Display>()

	const container = React.useRef<HTMLElement>(null)
	const [viewport, setViewport] = React.useState<Viewport>({width: 1, height: 1})

	// this feels like a hack!
	const anyDispatcher = (action:AnyAction) => {
		const dispatcher = action.type.startsWith('settings/') ? dispatch : dispatchDisplays
		dispatcher(action)
	}

	React.useEffect(() => {
		const i = distributeTimes
		if (i === undefined) return
		const display = displays[i]
		const {id, url} = display
		const video = getRef(id).current
		if (!video) return

		const matchingDisplays = displays.filter(i => i.url === url)
		// start with target display so it keeps its current time, bump up from there looping back to start
		const orderedDisplays = [
			display,
			...matchingDisplays.slice(i+1),
			...matchingDisplays.slice(0, i)
		]

		const t1 = video.currentTime
		const startTime = display.inTime || 0
		const duration = (display.outTime || video.duration) - startTime
		const spacing = duration / orderedDisplays.length

		orderedDisplays.forEach((v, i) => {
			const targetTime = t1 + (spacing * i)
			// loop time back to beginning once we exceed end of video
			getRef(v.id).current!.currentTime = targetTime % duration + startTime
		})
		dispatch(Settings.clearTrigger('distributeTimes'))
		// eslint-disable-next-line
	}, [distributeTimes])

	const updateTime = (index:number|undefined, property:keyof Display, name:keyof SettingsType['triggers']) => {
		if (index === undefined) return
		const display = displays[index]
		const currentTime = display[property] === undefined ? getRef(display.id).current!.currentTime : undefined
		dispatchDisplays(Displays.update({
			index,
			settings: {[property]: currentTime}
		}))
		dispatch(Settings.clearTrigger(name))
		// eslint-disable-next-line

	}
	React.useEffect(() => {
		updateTime(setIn, 'inTime', 'setIn')
		// eslint-disable-next-line
	}, [setIn])

	React.useEffect(() => {
		updateTime(setOut, 'outTime', 'setOut')
		// eslint-disable-next-line
	}, [setOut])

	const globalActions:TransitionalActionSet = React.useMemo(() => ({
		"f": ()=>toggleFullscreen(),
		"h": ()=>setHelp(!help),
		"s": Settings.nextFit(),
		"t": Settings.toggleThumbnails(),
		"x": Settings.nextAspect()
	}), [help])

	React.useEffect(() => {
		const handleResize = () => {
			const i = container.current!
			setViewport({width: i.clientWidth, height: i.clientHeight})
		}
		window.addEventListener('resize', handleResize)
		handleResize()
	}, [])

	React.useEffect(() => {
		displays.forEach((d, i) => dispatchDisplays(Displays.updateSettings({index: i, settings: {playbackRate: playbackRate.current}})))
		// I don't want to run this when getRef or displays runs!  eslint tells me I should
		// eslint-disable-next-line
	}, [playbackRate])

	React.useEffect(() => {
		displays.forEach((d, i) => dispatchDisplays(Displays.updateSettings({index: i, settings: {muted}})))
		// I don't want to run this when getRef or displays runs!  eslint tells me I should
		// eslint-disable-next-line
	}, [muted])

	React.useEffect(() => {
		const handleKeyDown = (ev:KeyboardEvent) => {
			const key = ev.key.toLowerCase()
			if (key in globalActions && !ev.shiftKey && !ev.ctrlKey) {
				const action = globalActions[key]
				if (typeof action === 'function') {
					return action()
				}
				return dispatch(action)
			}

			const index = active
			if (index === null) return
			const ctrlDisplayActions = {
				'arrowleft': Displays.updateSettings({index, settings: {playbackRate: displays[index].settings.playbackRate / 2}}),
				'arrowright': Displays.updateSettings({index, settings: {playbackRate: displays[index].settings.playbackRate * 2}}),
			}
			const shiftDisplayActions = {
				'arrowleft': Displays.updateSettings({index, settings: {currentTime: {current: getVideoTimeOffset(getRef(displays[index].id).current!, -.1)}}}),
				'arrowright': Displays.updateSettings({index, settings: {currentTime: {current: getVideoTimeOffset(getRef(displays[index].id).current!, .1)}}}),
			}

			const displayActions = {
				"delete": Displays.remove(index),
				"r": Displays.remove(index),
				"c": Displays.copy(index),
				"d": Settings.setTriggers({distributeTimes: index}),
				"e": Displays.excludeOthers(index),
				"i": Settings.setIn(index),
				"o": Settings.setOut(index),
			}

			if (ev.shiftKey) {
				key in shiftDisplayActions && dispatchDisplays(shiftDisplayActions[key as keyof typeof shiftDisplayActions])
			} else if (ev.ctrlKey) {
				key in ctrlDisplayActions && dispatchDisplays(ctrlDisplayActions[key as keyof typeof ctrlDisplayActions])
			} else {
				if (key in displayActions) {
					const action = displayActions[key as keyof typeof displayActions]
					const dispatcher = action.type.startsWith("settings/") ? dispatch : dispatchDisplays
					dispatcher(action)
				} else if (key >= "1" && key <= "9") {
					const copyActive = Displays.copy(index)
					Array(parseInt(key)).fill(null).forEach(()=>dispatchDisplays(copyActive))
					dispatch(Settings.setTriggers({distributeTimes: index}))
				}
			}
		}
		window.addEventListener('keydown', handleKeyDown)
		return () => window.removeEventListener('keydown', handleKeyDown)
	}, [displays, active, globalActions, getRef])

	React.useEffect(() => {
		const handleFileDrop = (e:DragEvent) => {
			stopEvent(e)
			if (!e.dataTransfer) return
			const droppedFiles = Array.from(e.dataTransfer.files)
			droppedFiles.map(file => dispatchDisplays(Displays.add({file, settings: {muted, playbackRate: playbackRate.current}})))
			setHelp(false)
		}
		document.addEventListener('drop', handleFileDrop)
		return () => document.removeEventListener('drop', handleFileDrop)
	}, [muted, playbackRate])

	const getRecommendedAspect = () => {
		const avgRatio = avg(displays.map(i => {
			const v = getRef(i.id).current!
			return v.videoWidth / v.videoHeight
		}))
		const closestRatio = [...aspectRatios].sort((a, b) => Math.abs(avgRatio - b.ratio) - Math.abs(avgRatio - a.ratio)).pop()!
		return closestRatio
	}

	const size = React.useMemo(() => getVideoSize(
		aspect.ratio,
		displays.length,
		viewport
	), [aspect, displays, viewport])

	const handleDrop = (e:React.DragEvent<HTMLElement>) => {
		dragSrc && dispatchDisplays(Displays.reorder({
			src: displays.indexOf(dragSrc),
			dest: displays.findIndex(i => getRef(i.id).current === e.target) || displays.length - 1
		}))
	}

	return <>
		<main ref={container} onDrop={handleDrop}>
			{displays.length === 0 && <Splash />}
			{displays.map((d, index) => <BDVideo
				key={d.id}
				ref={getRef(d.id)}
				size={size}
				objectFit={fit}
				display={d}
				showThumbnail={showThumbnails.current}
				handles={{
					load: () => dispatch(Settings.setAspect(getRecommendedAspect())),
					dragStart: () => setDragSrc(d),
					error: () => setErrors([...errors, d]),
					mouseOver: () => setActive(index),
					mouseOut: () => setActive(null),
					buttonPress: anyDispatcher
				}}
				buttons={[
					{label: 'X', action: Displays.remove(index)},
					{label: 'C', action: Displays.copy(index)},
					{label: 'E', action: Displays.excludeOthers(index)},
					{label: 'M', action: Displays.updateSettings({index, settings: {muted: {current: !d.settings.muted.current}}})},
					// I and O don't work - do I update state constantly, or use side effects?
					{label: 'I', action: Settings.setIn(index)},
					{label: 'O', action: Settings.setOut(index)},
					{label: '»', action: Displays.updateSettings({index, settings: {playbackRate: d.settings.playbackRate * 2}})},
					{label: '«', action: Displays.updateSettings({index, settings: {playbackRate: d.settings.playbackRate / 2}})}
				]}
			/>)}
		</main>
		{errors.length > 0 && <ErrorDisplay errors={errors} onClose={()=>setErrors([])} />}
		{help && <Help />}
	</>
}

export default App