import React, { Dispatch } from 'react'
import { ObjectFitProperty } from 'csstype'

import { fullscreenElement } from './FullScreen'
import { margins, browser, msBrowser } from './browser'
import { Thumbnail } from './Thumbnail'
import { IOBar } from './IOBar'
import { Viewport } from './App'
import './BDVideo.css'
import { AnyAction } from 'redux'
import { Display } from './reducers/Displays'

type BDVideoProps = {
	display:Display
	size:Viewport
	objectFit:ObjectFitProperty
	showThumbnail:boolean
	handles: {
		load:()=>void
		dragStart:()=>void
		error:()=>void
		mouseOver:()=>void
		mouseOut:()=>void
		buttonPress:Dispatch<AnyAction>
	},
	buttons: {
		label:string
		action:AnyAction
	}[]
}

const tryActions = (actions:{[key:string]:()=>void}, key:string) =>
	key in actions && actions[key]()

type ThumbnailState = {
	offset:number // x-axis mouse position
	timestamp:number
}

const dragMargin = 80
const thumbnailMargin = margins.bottom + 8

const overlayDuration = 4000
export const BDVideo = React.forwardRef((props:BDVideoProps, forwardRef:React.Ref<HTMLVideoElement>) => {
	const video = React.useRef<HTMLVideoElement>(null)

	const {showThumbnail, display, size, objectFit, handles, buttons} = props
	const {title, url, settings, outTime, inTime} = display
	const {load, dragStart, error, mouseOver, mouseOut, buttonPress} = handles

	const [overlayTimeout, setOverlayTimeout] = React.useState<NodeJS.Timeout>()
	const [thumbnailState, setThumbnailState] = React.useState<ThumbnailState>()

	const handleMouseOut = () => {
		setThumbnailState(undefined)
		mouseOut()
	}
	const handleMouseMove = (e:React.MouseEvent) => {
		overlayTimeout && clearTimeout(overlayTimeout)
		setOverlayTimeout(setTimeout(()=>setOverlayTimeout(undefined), overlayDuration))

		const v = video.current!

		if (!showThumbnail || browser === 'IE') return
		const {layerX, layerY} = e.nativeEvent as any
		const distanceFromBottom = v.height - layerY
		if (distanceFromBottom > thumbnailMargin) {
			return setThumbnailState(undefined)
		}
		const timelineWidth = v.width - margins.left - margins.right
		const videoPercentage = (layerX - margins.left) / timelineWidth 
		if (videoPercentage < 0 || videoPercentage > 1) {
			return setThumbnailState(undefined)
		}
		const targetTime = v.duration * videoPercentage
		setThumbnailState({
			offset: layerX,
			timestamp: targetTime
		})
	}

	const handleDragStart = (e:React.DragEvent<HTMLVideoElement>) => {
		const target = e.target! as HTMLVideoElement
		const distanceFromBottom = target.height - (e as any).offsetY
		if (distanceFromBottom < dragMargin) e.preventDefault()
		else dragStart()
	}

	React.useEffect(() => {
		video.current!.muted = settings.muted.current
	}, [settings.muted])

	React.useEffect(() => {
		if (settings.currentTime) {
			video.current!.currentTime = settings.currentTime.current
		}
	}, [settings.currentTime])

	React.useEffect(() => {
		video.current!.playbackRate = settings.playbackRate
	}, [settings.playbackRate])

	const handleLoad = () => {
		const v = video.current!
		if (v.currentTime < 1) v.currentTime = v.duration / 2
	}

	const handleTimeUpdate = () => {
		const v = video.current!
		const pastOut = outTime && outTime < v.currentTime
		// const beforeIn = inTime && inTime > v.currentTime
		// if (pastOut || beforeIn) v.currentTime = inTime || 0
		if (pastOut) v.currentTime = inTime || 0
	}

	const adjustDisplayTime = (adjustment:number, percentage:boolean = false) => {
		const v = video.current!
		if (percentage) adjustment = v.duration * adjustment
		v.currentTime = (v.currentTime + adjustment + v.duration) % v.duration
	}

	// memoize some of these, or entire groups?
	const shiftDisplayActions = {
		"arrowleft": () => adjustDisplayTime(-.1, true),
		"arrowright": () => adjustDisplayTime(.1, true),
		"f": () => fullscreenElement(video.current!)
	}
	const displayActions = {
		"m": () => { video.current!.muted = !video.current!.muted },
		"arrowleft": () => adjustDisplayTime(-60),
		"arrowright": () => adjustDisplayTime(60),
	}

	const handleDisplayKeyDown = (ev:React.KeyboardEvent<HTMLDivElement>) =>
		tryActions(
			ev.shiftKey ? shiftDisplayActions : displayActions,
			ev.key.toLowerCase()
		)

	return <div className="display"
		onMouseOver={mouseOver}
		onMouseMove={handleMouseMove}
		onMouseOut={handleMouseOut}
		onKeyDown={handleDisplayKeyDown}>
		{video.current && overlayTimeout && <>
			<div className="display-border" style={{pointerEvents: msBrowser ? 'none' : 'auto'}}>
				{/* playbackRate is not updating live - why not?  Have to move mouse which updates event handler in order to redraw
				is it possible I'm updating old Display object instead of setting a new one, and so props doesn't change? */}
				{title}{video.current.playbackRate !== 1 ? ` (${video.current.playbackRate.toFixed(2)}x)` : ''}
			</div>
			<div className="display-controls">
				{buttons.map(({label, action}) => <button key={label} onClick={()=>buttonPress(action)}>{label}</button>)}
			</div>
			<IOBar
				inTime={inTime}
				outTime={outTime}
				video={video.current}
			/>
		</>}
		{showThumbnail && thumbnailState && <Thumbnail src={url} {...thumbnailState} />}
		<video
			ref={ref => {
				(video as {current:HTMLVideoElement|null}).current = ref
				if (typeof forwardRef === 'function') {
					forwardRef(ref)
				} else if (forwardRef !== null) {
					(forwardRef as {current:HTMLVideoElement|null}).current = ref
				}
			}}
			controls autoPlay loop muted
			onError={error}
			onLoadedData={handleLoad}
			onLoadedMetadata={load}
			onTimeUpdate={outTime || inTime ? handleTimeUpdate : undefined}
			onDragStart={handleDragStart}
			src={url}
			draggable={!msBrowser}
			{...size}
			style={{objectFit}}
		/>
	</div>
})
