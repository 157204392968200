import {combineReducers} from 'redux'
import {useSelector as useReduxSelector, TypedUseSelectorHook} from 'react-redux'
import {configureStore} from '@reduxjs/toolkit'
import {Settings} from './Settings'
import {Displays} from './Displays'

const rootReducer = combineReducers({
	settings: Settings.reducer,
	displays: Displays.reducer,
})

export type RootState = ReturnType<typeof rootReducer>

export const store = configureStore({
	reducer: rootReducer
})

export const {dispatch} = store

export const useSelector:TypedUseSelectorHook<RootState> = useReduxSelector