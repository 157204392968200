import uuid from 'uuid'
import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {Setting} from './Settings'

export type Display = {
	id:string
	title:string
	type:string
	url:string
	inTime?:number
	outTime?:number
	settings:{
		muted:Setting<boolean>
		currentTime?:Setting<number>
		// playbackRate:Setting<number>
		playbackRate:number
	}
	// video?:HTMLVideoElement
	// triggerResize:boolean
}

type AddPayload = {
	file:File
	settings:Display['settings']
}
export const Displays = createSlice({
	name: 'Displays',
	initialState: [] as Display[],
	reducers: {
		excludeOthers: (state, {payload}:PayloadAction<number>) => [state[payload]],
		excludeDifferent: (state, {payload}:PayloadAction<number>) => {
			const {url} = state[payload]
			return state.filter(i => i.url === url)
		},
		remove: (state, {payload}:PayloadAction<number>) =>
			[...state.slice(0, payload), ...state.slice(payload+1)],
		copy: (state, {payload}:PayloadAction<number>) =>
			[...state, {...state[payload], settings: {...state[payload].settings}, id: uuid()}],
		// should use muted, playbackspeed from global settings for default
		add: (state, {payload:{file, settings}}:PayloadAction<AddPayload>) =>
			[...state, {
				id: uuid(),
				title: file.name,
				type: file.type,
				url: URL.createObjectURL(file),
				settings
			}],
		reorder: (state, {payload: {src: si, dest: di}}:PayloadAction<{src:number,dest:number}>) => {
			const d = state
			return (si > di ? [
				...d.slice(0, di),
				d[si],
				...d.slice(di, si),
				...d.slice(si+1)
			] : [
				...d.slice(0, si),
				...d.slice(si+1, di+1),
				d[si],
				...d.slice(di+1)
			])
		},
		update: (state, {payload: {index, settings}}:PayloadAction<{index:number, settings:Partial<Display>}>) => {
			const i = state[index]
			return [...state.slice(0, index), {...i, ...settings}, ...state.slice(index+1)]
		},
		updateSettings: (state, {payload: {index, settings}}:PayloadAction<{index:number, settings:Partial<Display['settings']>}>) => {
			const i = state[index]
			i.settings = {...i.settings, ...settings}
			// return [...state.slice(0, index), {...i, settings: {...i.settings, ...settings}, ...state.slice(index+1)}]
		},

		// updateAll: (state, {payload}:PayloadAction<Modifier<Display>>) => {
		// 	state.displays = state.displays.map(payload)
		// },
		// updateOne: (state, {payload}:PayloadAction<Partial<Display>>) => {
		// 	const {displays: d, active: a} = state
		// 	state.displays = [...d.slice(0, a), {...d[a], ...payload}, ...d.slice(a+1)]
		// },
	}
})

export const {actions, reducer} = Displays
